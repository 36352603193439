<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Офферы из Keitaro</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <v-text-field
          outlined
          dense
          hide-details
          clearable
          label="Название оффера"
          v-model="params.offer_name"
          @click:clear="onClear"
          @input="filterOffers"
        >
        </v-text-field>
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="countries"
          item-value="id"
          item-text="name"
          label="Гео"
          v-model="params.country"
          @click:clear="onClear"
          @input="filterOffers"
        ></v-select>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="updateOffers()"
        >Обновить список офферов из Keitaro</b-button>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="offers.results"
      paginated
      backend-pagination
      :total="offers.total"
      :per-page="params.perPage"
      @page-change="onPageChange"
      class="users-table"
    >
      <b-table-column label="Изображение c Erid" v-slot="props">
        <div class="image" style="width: 150px;">
          <img :src="props.row.img_path_with_erid" />
        </div>
      </b-table-column>

      <b-table-column label="Изображение без Erid" v-slot="props">
        <div class="image" style="width: 150px;">
          <img :src="props.row.img_path_without_erid" />
        </div>
      </b-table-column>

      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.keitaro_name }}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">
        <b-tooltip label="Редактировать">
          <b-button class="edit_icon" @click="edit(props.row)">
            <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
              <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
              <path d="M13.5 6.5l4 4"></path>
            </svg>
          </b-button>
        </b-tooltip>
      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            style="width: fit-content; float: left;"
            v-model="params.perPage"
            @change="filterOffers"
            :items="perPageOptions"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            dense
          />
        </div>
      </template>
    </b-table>

    <OverlayLoader :show="isLoading" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import OverlayLoader from "@/app/shared/components/app-loader/Loader";

export default {
  name: "OffersList",
  components: {
    draggable,
    OverlayLoader,
  },
  data() {
    return {
      params: {
        offer_name: "",
        country: null,
        perPage: 20,
        page: 1,
      },
      countries: [
        { id: "VN", name: "Вьетнам" },
        { id: "US", name: "США" },
        { id: "RU", name: "Россия" },
        { id: "KZ", name: "Казахстан" },
        { id: "PL", name: "Польша" },
        { id: "ES", name: "Испания" },
        { id: "MX", name: "Мексика" },
        { id: "AR", name: "Аргентина" },
        { id: "BR", name: "Бразилия" },
        { id: "EE", name: "Эстония" },
        { id: "LK", name: "Шри-Ланка" },
        { id: "empty", name: "Не указано" },
      ],
      perPageOptions: [
        {name: "10 на странице", value: 10},
        {name: "20 на странице", value: 20},
        {name: "50 на странице", value: 50},
        {name: "100 на странице", value: 100},
      ],
    };
  },
  created() {
    this.filterOffers();
  },
  computed: {
    offers() {
      return this.$store.getters.OFFERS;
    },
    isLoading() {
      return this.$store.getters.IS_OFFER_LOADING;
    },
  },
  methods: {
    onPageChange(page) {
      this.params.page = page;
      this.filterOffers();
    },
    edit(offer) {
      this.$router.push({path: `/offer/update/${offer.id}`}).catch(() => {
      });
    },
    onClear() {
      this.params.offer_name = "";
      this.params.country = null;
      this.filterOffers();
    },
    filterOffers() {
      this.$store.dispatch("GET_OFFERS", this.params);
    },
    updateOffers() {
      this.$store.dispatch("UPDATE_DEFAULT_OFFERS")
        .then(() => {
          this.$toast.success("Список офферов обновлен");
        })
        .catch(() => {
          this.$toast.error("Неизвестная ошибка!");
        });
    },
  },
};
</script>

<style>
.draggable-item {
  padding: 10px;
  background-color: #fff;
}

.table_offers_keit .column {
  padding: 0;
}

.table_offers_keit p {
  padding: 0 15px;
}

.table_offers_keit .title {
  font-weight: 400 !important;
}

.head_table {
  padding: 0 15px;
  margin-bottom: 0 !important;
}

.head_table p {
  letter-spacing: 15px !important;
  color: #0e4378;
}

.name {
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 0 !important;
}

.edit_icon:hover {
  background-color: #f8f9fa;
}

.edit_icon svg {
  stroke: #6d757d;
}

.delete_icon:active,
.edit_icon:active {
  top: 3px;
}

.edit_icon {
  padding: 3px 0 0 0;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  border-radius: 0.25rem;
  height: 2rem;
  width: 2rem;
  position: relative;
  box-shadow: none !important;
}
</style>
